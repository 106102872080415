<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1964_5140)">
      <path
        d="M12 0C5.38324 0 0 5.38324 0 12C0 18.6168 5.38324 24 12 24C18.6168 24 24 18.6168 24 12C24 5.38324 18.6168 0 12 0ZM12 1.8C17.644 1.8 22.2 6.35604 22.2 12C22.2 17.644 17.644 22.2 12 22.2C6.35603 22.2 1.8 17.644 1.8 12C1.8 6.35604 6.35603 1.8 12 1.8ZM11.9859 4.78828C11.7477 4.79201 11.5206 4.89008 11.3545 5.06099C11.1884 5.23191 11.0969 5.46171 11.1 5.7V13.5C11.0983 13.6193 11.1203 13.7377 11.1648 13.8483C11.2093 13.959 11.2753 14.0597 11.3591 14.1447C11.4428 14.2296 11.5426 14.297 11.6526 14.3431C11.7626 14.3891 11.8807 14.4128 12 14.4128C12.1193 14.4128 12.2374 14.3891 12.3474 14.3431C12.4574 14.297 12.5572 14.2296 12.6409 14.1447C12.7247 14.0597 12.7907 13.959 12.8352 13.8483C12.8797 13.7377 12.9017 13.6193 12.9 13.5V5.7C12.9016 5.57964 12.879 5.46019 12.8336 5.34871C12.7882 5.23723 12.7209 5.13599 12.6356 5.05099C12.5504 4.96598 12.449 4.89893 12.3374 4.85381C12.2258 4.80868 12.1063 4.7864 11.9859 4.78828ZM12 16.8C11.6817 16.8 11.3765 16.9264 11.1515 17.1515C10.9264 17.3765 10.8 17.6817 10.8 18C10.8 18.3183 10.9264 18.6235 11.1515 18.8485C11.3765 19.0736 11.6817 19.2 12 19.2C12.3183 19.2 12.6235 19.0736 12.8485 18.8485C13.0736 18.6235 13.2 18.3183 13.2 18C13.2 17.6817 13.0736 17.3765 12.8485 17.1515C12.6235 16.9264 12.3183 16.8 12 16.8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1964_5140">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconWarning',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
