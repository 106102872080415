<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1964_5112)">
      <path
        d="M12 0C5.38679 0 0 5.3868 0 12C0 18.6132 5.38679 24 12 24C18.6132 24 24 18.6132 24 12C24 5.3868 18.6132 0 12 0ZM16.8 8.4C17.1069 8.4 17.4138 8.51696 17.6484 8.75156C18.1176 9.22076 18.1176 9.97924 17.6484 10.4484L11.6484 16.4484C11.4144 16.6824 11.1072 16.8 10.8 16.8C10.4928 16.8 10.1856 16.6824 9.95156 16.4484L6.35156 12.8484C5.88236 12.3792 5.88236 11.6208 6.35156 11.1516C6.82076 10.6824 7.57924 10.6824 8.04844 11.1516L10.8 13.9031L15.9516 8.75156C16.1862 8.51696 16.4931 8.4 16.8 8.4Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1964_5112">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconCompleted',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
