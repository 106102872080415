<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1455_1213)">
      <path
        d="M12 0C5.38679 0 0 5.3868 0 12C0 18.6132 5.38679 24 12 24C18.6132 24 24 18.6132 24 12C24 5.3868 18.6132 0 12 0ZM12 2.4C17.3162 2.4 21.6 6.68385 21.6 12C21.6 17.3161 17.3162 21.6 12 21.6C6.68385 21.6 2.4 17.3161 2.4 12C2.4 6.68385 6.68385 2.4 12 2.4ZM12 6C11.6817 6 11.3765 6.12643 11.1515 6.35147C10.9264 6.57652 10.8 6.88174 10.8 7.2C10.8 7.51826 10.9264 7.82348 11.1515 8.04853C11.3765 8.27357 11.6817 8.4 12 8.4C12.3183 8.4 12.6235 8.27357 12.8485 8.04853C13.0736 7.82348 13.2 7.51826 13.2 7.2C13.2 6.88174 13.0736 6.57652 12.8485 6.35147C12.6235 6.12643 12.3183 6 12 6ZM11.9813 10.7836C11.6634 10.7886 11.3605 10.9194 11.139 11.1475C10.9176 11.3755 10.7956 11.6821 10.8 12V18C10.7978 18.159 10.8271 18.3169 10.8864 18.4645C10.9457 18.612 11.0338 18.7463 11.1454 18.8596C11.2571 18.9728 11.3901 19.0627 11.5368 19.1241C11.6835 19.1855 11.841 19.2171 12 19.2171C12.159 19.2171 12.3165 19.1855 12.4632 19.1241C12.6099 19.0627 12.7429 18.9728 12.8546 18.8596C12.9662 18.7463 13.0543 18.612 13.1136 18.4645C13.1729 18.3169 13.2022 18.159 13.2 18V12C13.2022 11.8395 13.1722 11.6801 13.1117 11.5314C13.0512 11.3826 12.9614 11.2476 12.8478 11.1341C12.7341 11.0207 12.5989 10.9312 12.4501 10.871C12.3012 10.8108 12.1418 10.7811 11.9813 10.7836Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1455_1213">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconInfo',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
