<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1991_5494)">
      <path
        d="M12 0C5.38324 0 0 5.38324 0 12C0 18.6168 5.38324 24 12 24C18.6168 24 24 18.6168 24 12C24 5.38324 18.6168 0 12 0ZM12 1.8C17.644 1.8 22.2 6.35604 22.2 12C22.2 17.644 17.644 22.2 12 22.2C6.35603 22.2 1.8 17.644 1.8 12C1.8 6.35604 6.35603 1.8 12 1.8ZM12.5695 3.58828C12.3467 3.59593 12.1347 3.68597 11.9745 3.84098C11.8143 3.99599 11.7173 4.20493 11.7023 4.42734L11.1023 11.9273C11.0901 12.0823 11.1182 12.2378 11.1839 12.3787C11.2497 12.5196 11.3507 12.641 11.4773 12.7313L15.6773 15.7313C15.7729 15.8075 15.8829 15.8634 16.0008 15.8957C16.1186 15.9281 16.2419 15.936 16.3629 15.9192C16.4839 15.9023 16.6003 15.861 16.7048 15.7977C16.8094 15.7344 16.8999 15.6505 16.971 15.5511C17.0421 15.4516 17.0921 15.3388 17.1182 15.2194C17.1442 15.1 17.1456 14.9765 17.1224 14.8565C17.0992 14.7366 17.0517 14.6226 16.983 14.5215C16.9143 14.4205 16.8257 14.3345 16.7227 14.2688L12.9375 11.5641L13.4977 4.57266C13.5097 4.44517 13.4945 4.31657 13.4529 4.19546C13.4113 4.07435 13.3443 3.9635 13.2565 3.87033C13.1686 3.77715 13.0619 3.7038 12.9434 3.65516C12.825 3.60652 12.6975 3.58372 12.5695 3.58828Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1991_5494">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconWait',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
