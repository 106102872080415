const asError = (err) => {
  if (err instanceof Error) return err

  const actions = err.config?.method ?? 'perform HTTP request'
  const endpoint = err.config?.url ?? '[undefined]'
  const status = err.response?.status ?? 0

  return Object.assign(new Error(`Failed to ${actions} on "${endpoint}" (${status})`), err)
}

export default async function sentryAxiosPlugin({ app: { $axios, $sentry } }, inject) {
  if (!$sentry || !$axios) {
    throw new Error(`nuxt-sentry-axios requires @nuxtjs/sentry and @nuxtjs/axios to be loaded`)
  }

  $axios.interceptors.request.use((config) => {
    const { transactionId = config.headers['X-Transaction-ID'] } = config
    if (transactionId === false || transactionId === null) {
      delete config.headers['X-Transaction-ID']
    } else {
      const id =
        typeof transactionId === 'string' && transactionId
          ? transactionId
          : Math.random().toString(36).substr(2, 9)

      config.headers['X-Transaction-ID'] = id

      $sentry.configureScope((scope) => {
        scope.setTransaction(id)
      })
    }

    return config
  })

  $axios.interceptors.response.use(null, (err) => {
    try {
      $sentry.configureScope((scope) => {
        const { config, response } = err
        const fingerprint = ['{{ default }}', 'axios']

        if (config) {
          fingerprint.push(config.baseURL)
          fingerprint.push(config.method)

          scope.setTag('xhr.lib', 'axios')
          scope.setTag('xhr.url', config.url)
          scope.setTag('xhr.method', config.method)
        }

        if (response) {
          const { status, statusText, headers, data } = response

          if (status >= 0) fingerprint.push(status)

          const contentType = headers?.['content-type']
          if (contentType) fingerprint.push(contentType)

          scope.setTag('xhr.status', status)
          if (status >= 400 && status < 500) scope.setLevel('warning')

          if (statusText) scope.setTag('xhr.statusText', statusText)

          const isJsonapi = contentType === 'application/vnd.api+json'
          if (isJsonapi) scope.setTag('jsonapi', isJsonapi) // use version instead ?

          if (isJsonapi && Array.isArray(data?.errors) && data.errors.length > 0) {
            // JSONAPI error response
            for (const error of data.errors) {
              if (typeof error?.code === 'string') fingerprint.push(error.code)
            }

            scope.setExtra('errors', data.errors)
          } else {
            scope.setExtra('response', {
              status,
              statusText,
              headers,
              data,
            })
          }
        } else {
          fingerprint.push('network-error')

          scope.setTag('xhr.statusText', err.code || err.message)
        }

        scope.setFingerprint(fingerprint.filter(Boolean).map(String))

        if (
          scope?._tags?.['xhr.status'] === 403 &&
          scope?._tags?.['xhr.url'] === '/core/v1/request_access'
        ) {
          // Skipping as this is not really an error
          return
        }
        $sentry.captureException(asError(err))
      })
    } catch (processingError) {
      try {
        $sentry.captureException(asError(err))
        $sentry.captureException(asError(processingError))
      } catch (err) {
        console.error('Failed to capture error', err)
      }
    }

    throw err
  })
}
