import type { NewEntity } from '@penbox-io/jsonapi-core'
import { ifString, negotiateLocale } from '@penbox-io/stdlib'

import type { Flow } from '../types'
import { translateString } from '../json-expression'

import { DEFAULT_USER_FIELDS, normalizeUserFields } from './user-field.js'

export type FlowEntity = NewEntity<Flow>

export const getFlowName = (flow: FlowEntity, locale: null | string = null) => {
  if (!flow) return undefined

  const { name, strings, locale: fallbackLocale } = flow.attributes
  return strings ? translateString(strings, locale, name, fallbackLocale) : name
}

export function getFlowLocales(flow: FlowEntity): undefined | string[] {
  // Fool-proof code in case "flow" is not a valid flow (e.g. when used in builder/studio)
  if (!flow?.attributes) return undefined

  const defaultLocale = ifString(flow.attributes.locale)

  const { strings, locales } = flow.attributes

  // v2 locales
  if (locales && Array.isArray(locales)) {
    return locales
  }

  if (strings && typeof strings === 'object') {
    const locales = Object.keys(strings)
    if (defaultLocale && !locales.includes(defaultLocale)) locales.push(defaultLocale)
    return locales
  }

  return defaultLocale ? [defaultLocale] : []
}

export function negotiateFlowLocale(
  flow: FlowEntity,
  locales?: string | string[]
): undefined | string {
  const attributes = flow?.attributes
  if (!attributes) return undefined

  return negotiateLocale((locale: string) => {
    if (locale === '__proto__') return false
    if (locale === attributes.locale) return true

    const { strings, locales } = attributes
    if (locales) {
      return locales.includes(locale)
    }

    if (strings) {
      const messages = strings?.[locale]
      return messages != null && typeof messages === 'object'
    }
    return false
  }, locales)
}

export function getFlowUserFields(flow: FlowEntity) {
  return (
    normalizeUserFields(flow?.meta?.['pnbx:requests:user:fields']) ||
    normalizeUserFields(DEFAULT_USER_FIELDS)
  )
}

export function getAllUserFields(flow: FlowEntity) {
  return [
    ...(normalizeUserFields(flow?.meta?.['pnbx:requests:user:fields']) || []),
    ...DEFAULT_USER_FIELDS,
  ].filter((field, index, self) => self.findIndex((f) => f.name === field.name) === index)
}

export function legacyAllowed(flow?: FlowEntity) {
  if (flow?.meta?.['pnbx:strict'] != null) return flow?.meta?.['pnbx:strict'] === false
  if (flow?.attributes?.options != null) return false
  return true
}
