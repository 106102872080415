import { ifString, once } from '@penbox-io/stdlib'
import {
  CountryCode,
  PhoneNumber,
  getCountries,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/max'
// google-libphonenumber is huge
// awesome-phonenumber is bigger & slower

import { type Dict, type FormHelpable, ifFormHelpable } from '../../common'

import { type FormError, buildError } from '../utils/error.js'

// libphonenumber does a lot of work to build this list
const getCountriesLazy = once(getCountries)

export type Value = string
export type Options = {
  label?: FormHelpable
  accept?: 'mobile' | 'fixed-line'
  autocomplete?: boolean
  hint?: string
  placeholder?: string
  country?: CountryCode
}

export function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    autocomplete: input.autocomplete === true,
    hint: ifString(input.hint),
    placeholder: ifString(input.placeholder),
    accept: input.accept === 'mobile' || input.accept === 'fixed-line' ? input.accept : undefined,
    country: parseCountry(input.country, locale),
  }
}

export function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('required', locale)
    return null
  }

  // If we don't have a country hint, the number must be international
  if (!options.country && !value.startsWith('+') && !value.startsWith('00')) {
    return buildError('phoneIntl', locale)
  }

  const pn = parsePhoneNumber(value, options)
  if (!pn) {
    return options.country
      ? buildError('phoneCountry', locale, [options.country])
      : buildError('phone', locale)
  }
  if (options.accept === undefined) return null
  const type = pn.getType()

  if (options.accept === 'mobile') {
    if (type === 'FIXED_LINE_OR_MOBILE' || type === 'MOBILE') return null
    return buildError('phoneMobile', locale, [type])
  } else if (options.accept === 'fixed-line') {
    if (type === 'FIXED_LINE_OR_MOBILE' || type === 'FIXED_LINE') return null
    return buildError('phoneFixedLine', locale, [type])
  }

  return null
}

function parseCountry(input: unknown, locale: string): undefined | CountryCode {
  if (input && typeof input === 'string') {
    const countries = getCountriesLazy() as string[]
    if (countries.includes(input)) return input as CountryCode
  }

  const territory = locale.length >= 5 ? locale.slice(3, 5) : undefined
  if (territory) {
    const countries = getCountriesLazy() as string[]
    if (countries.includes(territory)) return territory as CountryCode
  }

  const lang = locale.slice(0, 2)

  // Legacy: Default used to be BE when only the following languages were supported:
  if (lang === 'en' || lang === 'fr' || lang === 'nl' || lang === 'de') {
    return 'BE'
  }

  // Else, try and use the language as a country code (en_EN, fr_FR, nl_NL, de_DE, pt_PT, ...)
  // Not ideal, but still better than defaulting to US for everyone...
  if (lang) {
    const langUpper = lang.toUpperCase()
    const countries = getCountriesLazy() as string[]
    if (countries.includes(langUpper)) return langUpper as CountryCode
  }

  return undefined
}

export function parsePhoneNumber(value: null | Value, options: Options): undefined | PhoneNumber {
  if (!value) return undefined

  // Optimization (simple heuristic)
  if (value.length < 6) return undefined

  try {
    const pn = parsePhoneNumberFromString(value, {
      defaultCountry: options.country,
    })

    return pn?.isValid() ? pn : undefined
  } catch {
    // Probably invalid country code (should not happen)

    return undefined
  }
}
