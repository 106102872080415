<template lang="pug">
v-app(v-resize="setStyleVh" v-scroll="onScroll" :style="{ 'background-color': isHome ? 'white' : undefined }")
  //- Navigation
  v-app-bar.px-2.d-flex.justify-center.align-center.app-bar(
    app
    ref="appBar"
    flat
    :color="appbarColor"
    :dark="appbarIsDark"
    height="48"
    )

    .d-flex.flex-grow-1.align-center.px-2(style="max-width: 668px")
      //- Logo
      v-img.align-self-center(
        v-if="logo"
        :src="logo"
        contain
        max-width="200px"
        position="start center"
        height="32px")

      //- Menu Links
      v-sheet(v-if="$vuetify.breakpoint.smAndUp" :max-width="$vuetify.breakpoint.sm ? '30vw' : '50vw'").d-flex.flex-grow-1.mx-2.transparent
        v-slide-group
          v-slide-item(v-for="link, index in companyLinks" :key="index")
            v-list-item(link :href="link.url" target="_blank" :title="link.title" rel="noopener noreferrer")
              v-list-item-title {{ link.title }}
      v-spacer
      //- Locale + Help Center
      .d-flex.justify-end.align-center
        v-select.flex-grow-0(
          v-if="localeItems && localeItems.length > 1"
          style="max-width: 80px; border-radius: 4px"
          :items="localeItems"
          :value="locale"
          color="grey"
          dense
          solo
          background-color="#242b3d0f"
          flat
          hide-details
          @change="updateLocale($event)"
          )
        PHelpCenter.ml-2(
          v-if="showHelpBtn"
          v-on="declinable ? { decline: () => void setDeclined() } : {}"
          color="primary"
          data-test="help-center-appbar-btn"
          :phone="companyPhone"
          :email="companyEmail"
          :message="helpCustomText"
          )

  //- Main
  v-main
    nuxt
</template>

<style lang="sass" scoped>
.v-application :deep( > .v-application--wrap)
  min-height: calc(var(--vh, 1vh) * 100)

.v-main :deep( > .v-main__wrap)
  overflow: hidden

  & > :first-child
    width: 100%
    min-height: 100%

.app-bar
  border-bottom: 1px solid var(--surface-border) !important

::v-deep
  .v-toolbar__content
    padding-left: 0
    padding-right: 0
    flex-grow: 1
    display: flex
    justify-content: center
    max-width: 650px

  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control
    min-height: 30px
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Resize, Scroll } from 'vuetify/lib/directives'
import { PImg, isDark } from '@penbox-io/vuetify-lib'

import PHelpCenter from '~/components/p-help-center.vue'

const getType = (file) => {
  if (!file || typeof file !== 'string') return undefined
  if (file.endsWith('.ico')) return 'image/x-icon'
  if (file.endsWith('.png')) return 'image/png'
  if (file.endsWith('.jpg')) return 'image/jpeg'
  if (file.endsWith('.jpeg')) return 'image/jpeg'
}

const mediaQuery =
  typeof matchMedia === 'function' ? matchMedia('(prefers-color-scheme: dark)') : null

const getMatched = (route, prop = 'components') =>
  route.matched.flatMap((match) => Object.keys(match[prop]).map((key) => match[prop][key]))

/**
 * @this {any}
 */
function layoutMeta(Component) {
  const layout = Component.options.meta?.layout
  return typeof layout === 'function' ? layout(this) : layout
}

const optionsReducer = (acc, options) => ({ ...acc, ...options })

export default {
  name: 'default-layout',
  directives: {
    Resize,
    Scroll,
  },

  components: {
    PImg,
    PHelpCenter,
  },

  data(vm = this) {
    return {
      prefersDark: mediaQuery?.matches === true,
      wasDark: vm.$vuetify.theme.isDark,
    }
  },

  head() {
    return {
      refreshOnceOnNavigation: true,
      htmlAttrs: {
        lang: this.locale || this.$i18n?.fallbackLocale || 'en',
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/translate
        translate: 'no',
      },
      title: this.title || undefined,
      link: this.favicon
        ? //
          [{ hid: 'favicon', rel: 'icon', href: this.favicon, type: getType(this.favicon) }]
        : [{ hid: 'favicon', rel: 'icon', href: this.defaultFavicon, type: 'image/png' }],
      meta: [
        { hid: 'robots', once: true, name: 'robots', content: 'noindex, nofollow' },
        // https://developers.google.com/search/docs/advanced/crawling/special-tags
        { hid: 'google', once: true, name: 'google', content: 'notranslate' },
      ],
    }
  },

  computed: {
    ...mapGetters('branding', ['title', 'colors', 'logo', 'favicon', 'locale', 'locales', 'font']),
    ...mapGetters('request', ['declinable', 'showHelpBtn']),
    ...mapGetters('request', ['companyEmail', 'companyPhone', 'companyLinks']),
    ...mapGetters('request', ['helpCustomText']),

    localeItems() {
      return this.locales?.map((locale) => ({
        text: locale.toUpperCase(),
        value: locale,
      }))
    },

    navbarFallbackColor() {
      return this.isDark ? '#222222' : '#ffffff'
    },

    appbarColor() {
      return (
        this.colors?.appbar ||
        // Legacy: fallback to secondary
        this.colors?.secondary ||
        this.navbarFallbackColor
      )
    },

    appbarIsDark() {
      return isDark(this.appbarColor)
    },

    routeOptions() {
      // TODO: Get nuxt context somehow ?
      const context = { store: this.$store, router: this.$router, route: this.$route }
      const options = { allowDark: false, navBar: { hideOnScroll: true, scrollThreshold: 50 } }
      return getMatched(this.$route).map(layoutMeta, context).reduce(optionsReducer, options)
    },

    allowDark() {
      return this.routeOptions.allowDark === true
    },

    isDark() {
      return this.allowDark && this.prefersDark
    },

    navBarEmpty() {
      return this.logo == null && !(this.localeItems?.length > 1)
    },

    navBar() {
      // There is always a navbar
      return true
      // return this.navBarEmpty ? null : this.routeOptions.navBar
    },

    isHome() {
      return this.$route.path === '/'
    },

    defaultFavicon() {
      // Use a different color according to environment
      return this.$config.env === 'development'
        ? '/favicon-red.png'
        : this.$config.env === 'staging'
        ? '/favicon-green.png'
        : '/favicon.png'
    },
  },

  watch: {
    isDark: 'applyColors',
    colors: 'applyColors',
    font: { handler: 'applyFont', immediate: true },
    navBar(val, old) {
      // @hack: https://github.com/vuetifyjs/vuetify/issues/12505
      if (!val?.hideOnScroll) {
        const { appBar } = this.$refs
        if (appBar) appBar.isActive = true
      }
    },
  },

  mounted() {
    mediaQuery?.addListener(this.handleMediaQueryEvent)
    this.$nextTick(() => this.applyColors())
  },

  destroyed() {
    this.$vuetify.theme.isDark = this.wasDark
    this.$vuetify.theme.resetThemes()
    mediaQuery?.removeListener(this.handleMediaQueryEvent)
  },

  methods: {
    ...mapActions('branding', ['updateLocale']),
    ...mapActions('request', ['setDeclined']),

    onScroll() {
      // @hack: https://github.com/vuetifyjs/vuetify/issues/9993
      const { appBar } = this.$refs
      if (appBar) {
        if (appBar.currentScroll < appBar.currentThreshold) {
          appBar.isActive = true
        }
      }
    },

    handleMediaQueryEvent(event) {
      this.prefersDark = event.matches
    },

    setStyleVh() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },

    applyColors() {
      const colors = this.colors || {}
      const { themes } = this.$vuetify.theme

      for (const theme in themes) {
        const defaults = {
          primary: colors.primary || colors.accent || themes[theme].primary,
          accent: colors.primary || colors.accent || themes[theme].primary,
        }
        const value = {}
        for (const colorName in themes[theme]) {
          value[colorName] = colors[colorName] || defaults[colorName] || themes[theme][colorName]
        }
        this.$vuetify.theme.themes[theme] = value
      }

      this.$vuetify.theme.dark = this.isDark
    },

    applyFont() {
      console.log('start apply font', this.font)
      const font = this.font
      if (!font) return
      // font is an url to google font. Example
      // font:"https://fonts.googleapis.com/css2?family=Playwrite+IN:wght@100..400&display=swap"
      // Use the font as font-family for the body
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      console.log('link', link)
      link.href = font
      link.type = 'text/css'
      link.media = 'all'
      document.head.appendChild(link)
      console.log('added', link)
      // Add font-family: 'Playwrite IN'
      // this.fontFamily = font.split('family=')[1].split('&')[0].replace('+', ' ')
      const fontFamily = font.split('family=')[1].split('&')[0].split(':')[0].replace('+', ' ')
      // Update CSS variable --font-family
      document.documentElement.style.setProperty('--font-family', fontFamily)
      console.log('fontFamily', fontFamily)
    },
  },
}
</script>
