<template lang="pug">
.PHelpCenter.d-flex
  v-btn.d-flex.p-help-center.bluegray-400(
    v-if="label"
    v-bind="$attrs"
    v-on="$listeners"
    @click="helpDialog = true"
    style="width: 100%; border-color: rgb(223, 231, 239)"
    )

    span(v-text="label")
  .d-flex.align-center.justify-center.cursor--pointer.bluegray-400(v-else @click="helpDialog = true")
    IconInfo(:size="18")

  v-dialog(v-model="helpDialog" width="600px" :fullscreen="$vuetify.breakpoint.xs" internal-activator)
    v-card.overflow-x-hidden.overflow-y-auto.d-flex.flex-column
      v-card-title {{ $t('title') }}
        v-spacer
        v-btn(icon @click="helpDialog = false")
          v-icon(v-text="$options.icons.mdiClose")

      v-card-text.flex-grow-0(v-if="message")
        p {{ message }}

      v-card-text.flex-grow-0(v-else-if="phoneNormalized || emailNormalized")
        h3.text-h6.primary--text {{ $t('contact_title') }}
        p {{ $t('contact_text') }}

        v-row

          v-col(v-if="phoneNormalized" cols="12")
            v-btn(:href="`tel:${phoneNormalized}`" outlined :color="color" width="100%" data-test="help-center-phone-btn")
              v-icon(left v-text="$options.icons.mdiPhone")
              span.text-truncate {{ phoneFormatted }}

          v-col(v-if="emailNormalized" cols="12")
            v-btn(:href="`mailto:${emailNormalized}`" outlined :color="color" width="100%" data-test="help-center-email-btn")
              v-icon(left v-text="$options.icons.mdiEmail")
              span.text-truncate {{ emailFormatted }}

      v-spacer

      v-card-text.flex-grow-0
        p {{ $t('decline_text') }}

        v-row

          v-col(cols="12")
            v-dialog(v-model="warningDialog" width="400px")

              template(#activator="{ attrs, on }")
                v-btn.mb-2(v-bind="attrs" v-on="on" :disabled="!$listeners.decline" :color="color" block width="100%" data-test="help-center-decline-btn") {{ $t('decline_btn') }}

              v-card
                v-card-title.mb-2 {{ $t('warning_title') }}
                v-card-subtitle {{ $t('warning_subtitle') }}
                v-card-actions
                  v-spacer
                  v-btn(:color="color" text @click="decline" data-test="help-center-decline-confirm-btn") {{ $t('warning_confirm') }}
                  v-btn(:color="color" @click="warningDialog = false" data-test="help-center-decline-cancel-btn") {{ $t('warning_cancel') }}

</template>

<script>
import { mdiClose, mdiEmail, mdiMessageQuestion, mdiPhone } from '@mdi/js'
import translations from '~/i18n/components/p-help-center.js'
import { IconInfo } from '~/icons'

export default {
  name: 'p-help-center',
  inheritAttrs: false,

  components: { IconInfo },

  props: {
    color: { type: String, default: 'primary' },
    label: { type: String, default: () => null },

    phone: { type: String, default: null },
    email: { type: String, default: null },
    message: { type: String, default: null },
  },

  icons: {
    mdiClose,
    mdiEmail,
    mdiMessageQuestion,
    mdiPhone,
  },

  data() {
    return {
      helpDialog: false,
      warningDialog: false,
    }
  },
  i18n: { messages: translations },

  computed: {
    emailNormalized() {
      if (!this.email) return null
      return this.email
        .toLowerCase()
        .trim()
        .replace(/^<[^>]*>\s*/g, '')
    },
    emailFormatted() {
      return this.emailNormalized
    },

    phoneNormalized() {
      if (!this.phone) return null
      return String(this.phone)
        .replace(/\(.+\)/g, '')
        .replace(/[^0-9+]/g, '')
        .replace(/^00/, '+')
    },
    phoneFormatted() {
      if (!this.phone) return null
      return this.phone // TODO: actually format
    },
  },

  methods: {
    decline(event) {
      this.helpDialog = false
      this.warningDialog = false

      this.$emit('decline')
    },
  },
}
</script>
