<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1964_5122)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9998 22.9999C18.0749 22.9999 22.9998 18.0751 22.9998 11.9999C22.9998 5.92481 18.0749 0.999939 11.9998 0.999939C5.92468 0.999939 0.999817 5.92481 0.999817 11.9999C0.999817 18.0751 5.92468 22.9999 11.9998 22.9999ZM11.9998 23.9999C18.6272 23.9999 23.9998 18.6274 23.9998 11.9999C23.9998 5.37252 18.6272 -6.10352e-05 11.9998 -6.10352e-05C5.3724 -6.10352e-05 -0.000183105 5.37252 -0.000183105 11.9999C-0.000183105 18.6274 5.3724 23.9999 11.9998 23.9999Z"
        fill="currentColor"
      />
      <path
        d="M11.9976 3.99969C11.9976 3.44741 12.4467 2.99395 12.9956 3.05519C14.5406 3.22757 16.0208 3.7981 17.2877 4.71854C18.8253 5.83569 19.9698 7.41095 20.5571 9.21854C21.1444 11.0261 21.1444 12.9733 20.5571 14.7808C20.0732 16.2702 19.2111 17.6018 18.0625 18.6494C17.6544 19.0215 17.0245 18.9186 16.6999 18.4718L12.1886 12.2626C12.0645 12.0917 11.9976 11.886 11.9976 11.6748V3.99969Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1964_5122">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconProgress',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
