import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Ff4f34dc9063d45b1a59b1e7679e70475@o315059.ingest.sentry.io\u002F1794775",
    environment:"staging",
    normalizeDepth:6,
    beforeSend:(event, hint) => {
        if (
        hint.originalException &&
        hint.originalException.message && (
        hint.originalException.message.includes('Request aborted') ||
        hint.originalException.message.includes('Network Error')))
        {
          event.level = 'warning';
        }

        return event;
      },
    release:"9f2be7622b2896ba73bdacc121511e3b1e11c144",
    serverName:"aiboov-fill",
    requestBodies:true,
    ignoreInternal:false,
    ignoreErrors:["chrome-extension:\u002F\u002F","top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http:\u002F\u002Ftt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http:\u002F\u002Floading.retry.widdit.com\u002F","atomicFindClose","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage","ReportingObserver","NavigationDuplicated","Redirected when going from \"","Navigation cancelled from \"","Navigation aborted from \""]
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData({depth:20}),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
